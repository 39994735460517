import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/Layout';
import InfoCard from '../../components/InfoCard';
import { DataPageSidebarLinks } from '../../helpers/constants';
import datagaps from "../../images/research-datagaps.png";
import democratizing from "../../images/research-democratizing.png";
import covid from "../../images/research-covid.jpg";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="Research Reports" sidebarLinks={DataPageSidebarLinks} activeHeaderLink="Data" mdxType="Layout">
      <h1>{`Research reports`}</h1>
      <p>{`Reports by members of the Environmental Enforcement Watch (EEW), using the `}<Link to='/data/notebooks' rel='noreferrer noopener' mdxType="Link">{`Notebooks`}</Link>{` and `}<Link to='/learn/events' rel='noreferrer noopener' mdxType="Link">{`participatory knowledge-making`}</Link>{` that are core to EEW's process.`}</p>
      <div className='row'>
  <InfoCard title="Data Gaps Report" description='How Gaps and Disparities in EPA Data Undermine Climate and Environmental Justice Screening Tools' link='https://envirodatagov.org/publication/how-gaps-and-disparities-in-epa-data-undermine-climate-and-environmental-justice-screening-tools/' image={datagaps} mdxType="InfoCard" />
  <InfoCard title="Democratizing Data Report" description='Democratizing Data: Environmental Enforcement Watch’s Report Cards for Congressional Oversight of the EPA' link='https://envirodatagov.org/press-release-democratizing-data-reports-released-by-edgis-environmental-enforcement-watch/' image={democratizing} mdxType="InfoCard" />
  <InfoCard title="COVID Enforcement Report" description='More Permission to Pollute: The Decline of EPA Enforcement and Industry Compliance during COVID' link='https://envirodatagov.org/more-permission-to-pollute-the-decline-of-epa-enforcement-and-industry-compliance-during-covid/' image={covid} mdxType="InfoCard" />
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      